import React from "react";
import { useSWRConfig } from "swr";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import { Checkbox, Collapse, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import {
  stravaColor,
  pulseBackground,
} from "assets/jss/nextjs-material-kit-pro.js";
import FeatureExample from "pages-sections/dashboard/settings/FeatureExample";

import { usePopUp } from "components/Providers/PopUpProvider";

import { NotLiveYetMessage } from "../../pages-sections/dashboard/SettingsSection";

const checkInputIsTruthy = (input) => {
  if (!input) {
    return input;
  }
  if (typeof input === "boolean") {
    return input;
  } else if (typeof input === "object") {
    return (
      Object.keys(input).length &&
      Object.values(input).some(
        (value) => value !== undefined && value !== null
      )
    );
  }
  return false;
};

const getLoadingStateKey = (option, checkbox) =>
  `${option.name}-${checkbox.value}`;

const useStyles = makeStyles()((theme) => ({
  pulseBackground,
  loadingRight: {
    left: 27,
  },
  loadingLeft: {
    left: 7,
  },
  loadingCircularOverlay: {
    color: stravaColor,
    position: "absolute",
    top: 7,
    zIndex: -1,
    opacity: 1,
  },
  followOnInputBox: {
    marginLeft: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderLeft: "1px solid #ccc",
    columnGap: theme.spacing(1),
    display: "block",
    width: "100%",
  },
  positionRelative: { position: "relative", zIndex: 10 },
  errorMessage: {
    color: stravaColor,
    fontSize: "0.75rem",
    lineHeight: 1,
    marginTop: theme.spacing(0.5),
  },
  marginRight: { marginRight: theme.spacing(2) },
  checkboxHorizontalGroup: {
    display: "flex",
    flexDirection: "row",
    //  marginBottom: theme.spacing(1),
  },
}));

export default function SwitchGroup(props) {
  const { classes, cx } = useStyles();

  const { mutate } = useSWRConfig();
  const {
    helperText,
    label,
    labelClasses,
    helperTextClasses,
    options,
    customHandleChange,
    controlled,
    keys,
    type,
    id,
    isUpdating,
    isUpdatingError,
    error,
    disabled,
    shouldValidate,
    preventSelecting,
    isPreventedPopUpKey,
  } = props;
  const initState = {};
  if (options) {
    for (const option of options) {
      initState[option.name] = option.isSelected;
    }
  }
  const [state, setState] = React.useState(initState);

  const [loadingState, setLoadingState] = React.useState(null);
  const [_, setPopUpProps] = usePopUp();

  const handleChange = (event, option) => {
    const vars = { event, type, keys, id, setLoadingState, ...option };
    if (shouldValidate && preventSelecting && !option.isSelected) {
      setPopUpProps({
        mutate,
        contentKey: isPreventedPopUpKey,
        onAccept: () => {
          if (option.customHandleChange) {
            option.customHandleChange(vars);
          } else if (customHandleChange) {
            customHandleChange(vars);
          }
        },
      });
      return false;
    }
    if (!controlled) {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      if (option.customHandleChange) {
        option.customHandleChange(vars);
      } else if (customHandleChange) {
        customHandleChange(vars);
      }
    }
  };

  React.useEffect(() => {
    if (!isUpdating && loadingState) {
      setLoadingState(null);
    }
  }, [isUpdating, loadingState]);
  return (
    <FormControl
      variant="standard"
      error={
        error ||
        (isUpdatingError && options.some((o) => o.name === isUpdatingError))
      }
      component="fieldset"
    >
      {label && (
        <FormLabel className={labelClasses} component="legend">
          {label}
        </FormLabel>
      )}
      <FormGroup>
        {options &&
          options.map((option, index) => {
            const label = option.showExample ? (
              <>
                <span className={classes.marginRight}>{option.label}</span>
                <FeatureExample name={option.name} />
              </>
            ) : (
              option.label
            );
            const checked = controlled
              ? checkInputIsTruthy(option.isSelected) //Boolean(option.isSelected)
              : state[option.name];

            let key = option.name || index;
            if (id) {
              key = key + id;
            }
            if (label) {
              key = key + label;
            }
            return (
              <React.Fragment key={key}>
                {!option.checkboxGroup && (
                  <FormControlLabel
                    classes={{ root: labelClasses }}
                    label={label}
                    control={
                      <div className={classes.positionRelative}>
                        <Switch
                          checked={checked}
                          onChange={(e) => handleChange(e, option)}
                          name={option.name}
                          disabled={disabled || option.disabled}
                        />
                        {option.name && loadingState == option.name && (
                          <CircularProgress
                            size={24}
                            thickness={10}
                            className={
                              classes.loadingCircularOverlay +
                              " " +
                              (checked
                                ? classes.loadingRight
                                : classes.loadingLeft)
                            }
                          />
                        )}
                      </div>
                    }
                  />
                )}
                {option.checkboxGroup && (
                  <FormLabel classes={{ root: labelClasses }}>
                    {label}
                  </FormLabel>
                )}
                {option.helperText && (
                  <div className={helperTextClasses}>{option.helperText}</div>
                )}
                {option.checkboxGroup && (
                  <>
                    <div
                      className={cx(
                        classes.checkboxHorizontalGroup,
                        classes.followOnInputBox
                      )}
                    >
                      {option.checkboxGroup.map((checkbox, index) => (
                        <FormControlLabel
                          key={checkbox.name || checkbox.label}
                          sx={{ ml: 0 }}
                          control={
                            <>
                              <Checkbox
                                checked={option.isSelected[checkbox.value]}
                                disabled={isUpdating}
                                onChange={(e) => {
                                  setLoadingState(
                                    getLoadingStateKey(option, checkbox)
                                  );
                                  const event = {
                                    ...e,
                                    target: {
                                      ...e.target,
                                      name: option.name,
                                      value: {
                                        ...option.isSelected,
                                        [checkbox.value]: e.target.checked,
                                      },
                                    },
                                  };

                                  if (option.customHandleChange) {
                                    option.customHandleChange(
                                      event,
                                      option.value,
                                      loadingState
                                    );
                                  } else if (customHandleChange) {
                                    customHandleChange(
                                      event,
                                      option.value,
                                      loadingState
                                    );
                                  }
                                }}
                                classes={{
                                  root: cx({
                                    [classes.pulseBackground]:
                                      loadingState ===
                                      getLoadingStateKey(option, checkbox),
                                  }),
                                }}
                              />
                            </>
                          }
                          label={checkbox.label}
                        />
                      ))}
                    </div>
                  </>
                )}
                {isUpdatingError && option.name === isUpdatingError && (
                  <div className={classes.errorMessage}>
                    {"Error updating " + option.label}
                  </div>
                )}
                <Collapse in={Boolean(checked && option.followOnInput)}>
                  <div className={classes.followOnInputBox}>
                    {option.followOnInput}
                  </div>
                </Collapse>
                <Collapse
                  in={Boolean(
                    checked && option.notLiveYet && loadingState != option.name
                  )}
                >
                  <NotLiveYetMessage />
                </Collapse>
                {index < options.length - 1 && (
                  <Divider
                    variant="middle"
                    sx={{ marginTop: 1.5, marginBottom: 0.5 }}
                  />
                )}
              </React.Fragment>
            );
          })}
      </FormGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {error && (
        <div className={classes.errorMessage}>
          {"We're sorry, something went wrong with your request"}
        </div>
      )}
    </FormControl>
  );
}
